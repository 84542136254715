import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Image from 'react-bootstrap/Image';
// import NavDropdown from 'react-bootstrap/NavDropdown';
import Offcanvas from 'react-bootstrap/Offcanvas';
import React, { useState } from 'react';

import { useAuth } from './AuthProvider';
import { useImages } from './ImageProvider';

export const NavbarContext = React.createContext();

export const useNavbar = () => React.useContext(NavbarContext);

export const NavbarProvider = ({ children }) => {
  const [navShowing, setNavShowing] = useState(false);
  const [docsShowing, setDocsShowing] = useState(false);

  const toggleShowDocs = () => setDocsShowing((s) => !s);

  const showNav = setNavShowing;
  const showDocs = setDocsShowing;

  return (
    <NavbarContext.Provider value={{ navShowing, showNav, docsShowing, showDocs, toggleShowDocs}}>
      {children}
    </NavbarContext.Provider>
  );
};


function ChassidNavbar(props) {

  const {showNav, navShowing} = useNavbar();
  const {toggleShowDocs} = useNavbar();


  const { user, authenticated, logout } = useAuth();
  const { selectedImage } = useImages();

  return (
    <>
      {['xxl'].map((expand) => (
        <Navbar key={expand} expand={expand} className="bg-body-tertiary mb-3" sticky="top">
          <Container >
            <Navbar.Brand href="#">
              <img
                alt=""
                src="/logo192.png"
                width="30"
                height="30"
                className="d-inline-block align-top"
              />{' '}
              Chevrusa.net</Navbar.Brand>
            
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={navShowing}
            >
              <Offcanvas.Header closeButton onClick={() => { showNav(false) }}>

              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-begin flex-grow-1 pe-3">
                  {/* <Nav.Link href="#action1">Home</Nav.Link>
                  <Nav.Link href="#action2">Link</Nav.Link> */}
                 
                  {/* <ButtonToolbar aria-label="Documents">

                  <Button variant="primary" onClick={ () => {props.handleShowDocs();showNav(false)}}>
                    Documents
                  </Button> 
                  </ButtonToolbar>
                */}
                {selectedImage && <ButtonGroup aria-label="Image Tools" size="sm">
                  {props.currentTools}
                  </ButtonGroup>
                }
                </Nav>
                
                
              

                 
                  
                  
              
              </Offcanvas.Body>
            </Navbar.Offcanvas>
            
            {authenticated && 
            <>
            <Navbar.Toggle className="ml-auto" onClick={() => { toggleShowDocs() }} style={{ 'marginLeft': 'auto', marginRight: '10px' }} aria-controls={`offcanvasNavbar-expand-${expand}`} />            
            <Form className="d-flex">
            <NavDropdown className="ml-auto" 
              title={<Image src={user?.image} roundedCircle style={{ width: '28px', height: '28px', margin: '4px', border: '1px solid' }} />}
              id={`offcanvasNavbarDropdown-expand-${expand}`} drop='down-centered' align="end">
              <NavDropdown.Header>
                <Image src={user?.image} roundedCircle style={{ width: '28px', height: '28px', margin: '4px', border: '1px solid' }} />
                {user?.name}
              </NavDropdown.Header>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action4"onClick={logout}>Logout</NavDropdown.Item>
            </NavDropdown>
            </Form>
            </>
                }
          </Container>
        </Navbar>
      ))}
    </>
  );
}

export default ChassidNavbar;