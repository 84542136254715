import React, { useState, useEffect, useRef, memo } from 'react';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Trash3 } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import { useImages } from './ImageProvider';

import PullToRefresh from 'react-simple-pull-to-refresh';

import { useNavbar } from './ChassidNavbar';

import dayjs from 'dayjs';
import ButtonGroup from 'react-bootstrap/esm/ButtonGroup';
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)


// images={images} removeImage={removeImage} selectedImageId={selectImage}

function ImageGallery() {

  const galleryRef = useRef();
  
  // const cardRefs = useRef([]);

  const [confirmDelete, setConfirmDelete] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const [removingImage, setRemovingImage] = useState(null);
  const [imagesFetched, setImagesFetched] = useState(false);

  const {showDocs, docsShowing} = useNavbar();
  const handleClose = () => showDocs(false);

  const { fetchImages, fetchMoreImages, removeImage, images, selectImage, selectedImage } = useImages();

  useEffect(() => {
    console.log('ImageGallery: docsShowing', docsShowing, galleryRef);
    if (imagesFetched && galleryRef.current) {
      if(galleryRef.current?.click) galleryRef.current?.click();
    }
//   document.addEventListener('mousemove', function(event) {
//     const x = event.clientX, y = event.clientY;
//     const elementUnderPointer = document.elementFromPoint(x, y);

//     if (elementUnderPointer.scrollHeight > elementUnderPointer.clientHeight ||
//         elementUnderPointer.scrollWidth > elementUnderPointer.clientWidth) {
//         console.log('Scrollable element under pointer:', elementUnderPointer);

//         // To make the element non-scrollable
//         elementUnderPointer.style.overflow = 'hidden';

//         // Or to make the element scrollable
//         // elementUnderPointer.style.overflow = 'auto';
//     }
// });
  }, [docsShowing]);

  const handleShow = () => {
    console.log('ImageGallery: docsShowing', docsShowing, galleryRef);

    if (galleryRef.current?.click) {
      galleryRef.current.click();
    }
  };

  const deleteImage = async () => {
    try {
      setRemovingImage(imageToDelete);
      await removeImage(imageToDelete.id);
    } catch (error) {
      console.error('Error deleting image:', error);
    }
    finally {
      setTimeout(handleCancelDelete, 1000);
    }
  };



  const handleDelImage = (image) => {
    setImageToDelete(image);
    setConfirmDelete(!!image);
  };

  const handleCancelDelete = () => {
    handleDelImage(null);
  };

  const handleImageSelect = (image) => {
    // console.log(image)
    // setSelectedImage(image);
    handleClose();
    selectImage(image.id);

  };

  return (
    <>

<Offcanvas ref={galleryRef} show={docsShowing} onHide={handleClose} onEnter={handleShow} enforceFocus={true} scroll={false} backdrop={false}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Documents</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                
      <Container id="image-gallery" className="image-gallery" >
        <PullToRefresh onRefresh={fetchImages} canFetchMore={true} onFetchMore={fetchMoreImages} >
          <Row  sm={1} md={2}>
            {images.map((image, idx) => (
              <Col key={'image_' + image.id || 'pl' + idx} >
                <Card style={{ width: '100%', marginBottom: '15px' }} bg={selectedImage === image ? 'info' : 'light'} onClick={() => handleImageSelect(image)}
                  className={`${imageToDelete === image ? 'blur' : ''} ${removingImage?.id === image?.id ? 'removing' : ''}`}>

                  <Card.Img variant="top" src={image.id ? "/api/images/"+image.id+"/w341.jpg" : "holder.js/100px180"} />{/*"/images/"+image.id+".jpg"*/}
                  <Card.Body>
                    <Card.Title>Image: #{image.id}</Card.Title>
                    <Card.Text>
                      {image.createdAt &&
                        dayjs().to(image.createdAt)
                      }
                    </Card.Text>
                    <Form className={imageToDelete === image ? 'hide' : 'show'}>
                      <Trash3 onClick={(event) => {
                        event.stopPropagation();
                        setConfirmDelete(true);
                        handleDelImage(image);
                      }}></Trash3>
                    </Form>
                    <ButtonGroup size='sm' className={confirmDelete && imageToDelete === image ? 'show' : 'hide'}>
                    <Button variant="secondary" size='sm' onClick={(event)=> {event.stopPropagation(); handleCancelDelete();}}>
                      Cancel
                    </Button>
                    <Button variant="danger" size='sm' onClick={(event) => {
                      event.stopPropagation();
                      // Delete the image
                      setConfirmDelete(false);
                      deleteImage();
                    }}>
                      <Trash3 />
                    </Button>
                    </ButtonGroup>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </PullToRefresh>
      </Container>
                    {/* {document.getElementById("image-gallery").focus()} */}
      </Offcanvas.Body>
          </Offcanvas>
      <Modal  onHide={handleCancelDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this image?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => {
            // Delete the image
            setConfirmDelete(false);
            deleteImage();
          }}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default memo(ImageGallery);



