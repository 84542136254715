
import React, { createContext, useContext, useState, useEffect } from 'react';
import Cookies from 'js-cookie';

export const UserContext = createContext();

export const useAuth = () => useContext(UserContext);   

 const AuthProvider = ({ children }) => {
  // console.log('AuthProvider');

  const [user, setUser] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    if (!user) {
      let u = localStorage.getItem('user');
      u = (JSON.parse(u || 'null'));
      login(u);
    }
  },[]);

  const login = (user) => {
    console.log('login', user);
    setAuthenticated(!!user);
    // Optionally save token to local storage
    setUser( user );
    localStorage.setItem('user', JSON.stringify(user));

  };

  const logout = () => {
    setAuthenticated(false);
    localStorage.removeItem('user');
    Cookies.remove('jwt'); // Delete the 'jwt' cookie
    setUser(null);
  };

  return (
    <UserContext.Provider value={{ user, login, logout, authenticated }}>
      {children}
    </UserContext.Provider>
  );
};

export default AuthProvider;