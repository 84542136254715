import React, { memo, useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import ToggleButton from 'react-bootstrap/ToggleButton';

import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Stack from 'react-bootstrap/Stack';

import Form from 'react-bootstrap/Form';
import { Trash3, FileImage, FileText, Layers } from 'react-bootstrap-icons';

import '../styles/HocrProofreaderWrapper.css';

import {useAuthenticatedApi} from './ApiProvider';

import { useImages } from './ImageProvider';

// import svgDragSelect from "svg-drag-select"
// import SvgDragSelectWrapper from './SvgDragSelectWrapper';

const HocrProofreaderWrapper = forwardRef(({ toolbar, updateImage }, ref) => {

  
  const { selectedImage } = useImages();

  const [image, setImage] = useState(null);

  const [isSvgShown, setIsSvgShown] = useState(false);

  const [showingImage, setShowingImage] = useState(true);

  const toggleSvg = () => setIsSvgShown((prev) => !prev);

  const [selectedElement, setSelectedElement] = useState(null);

  const hocrProofreaderInstance = useRef();
  const [translation, setTranslation] = useState('');

  // Refs for the container elements where the HocrProofreader will render its content
  const layoutContainerRef = useRef(null);
  const editorContainerRef = useRef(null);
  const translationContainerRef = useRef(null);

  const { get, post } = useAuthenticatedApi();

  useEffect(() => {

    if(!selectedImage || !layoutContainerRef.current) return;

    console.log('HocrProofreaderWrapper: selectedImage:', layoutContainerRef, editorContainerRef, selectedImage);

    const handleSelectedElementChange = (newSelectedElement) => {
      setSelectedElement(newSelectedElement);
    };

    // Dynamically import the hocr-proofreader.js script
    import('./hocr-proofreader.js').then(async (module) => {
      // Assuming the script exposes a HocrProofreader constructor
      const config = {
        layoutContainer: layoutContainerRef.current.id,
        editorContainer: editorContainerRef.current.id,
        // Add other necessary configuration options here
      };
      // Initialize the HocrProofreader with the config
      hocrProofreaderInstance.current = new module.HocrProofreader(config);
      setTranslation([]);

      const result = await get('images/' + selectedImage.id + '.json');

      const data = await result.json();
      console.log('HocrProofreaderWrapper: selectedImage:', data);
      const { id, fullAnnotation } = data;
      
      setImage(data);

      if (data.translation?.length && data.translation[0])
        setTranslation(data.translation);
      else
        setTranslation([]);
      
      hocrProofreaderInstance.current.setHocr(id, `/api/images/${id}/w800.jpg`, fullAnnotation?.hocr || '');


      // Assuming hocrProofreader is the instance of your hocr-proofreader
      hocrProofreaderInstance.current.on('selectedElementChange', handleSelectedElementChange);


    });

   

   
    // Cleanup function
    return () => {
      hocrProofreaderInstance.current.off('selectedElementChange', handleSelectedElementChange);
    };

  }, [selectedImage]);



  useEffect(() => {
    toolbar(ProofreaderToolbar);
  }, [image]);

  useEffect(() => {
    if (!selectedImage || !updateImage) return;
    imageUpdater(selectedImage);
  }, [selectedImage, updateImage]);


  useEffect( () => {
    if(!selectedImage) return;
    if (!isSvgShown) {
      // If the SVG is currently shown, remove it
      removeGoogleBlocks();
    } else {
      // If the SVG is not currently shown, show it
      showGoogleBlocks();
    }
  }, [isSvgShown]);

  useEffect(() => { 
    if (!selectedImage || !selectedElement) return;
    console.log('HocrProofreaderWrapper: selectedElement:', selectedElement);
    const getSelectedText = async () => {
      const response = await get(`/images/${selectedImage.id}.txt?bbox=${stringifySVGRectSet(hocrProofreaderInstance.current.selectedElements)}`)
      const text = await response.text();
      console.log('HocrProofreaderWrapper: getSelectedText:', text);
    }
    getSelectedText();
  }, [selectedImage, selectedElement]);



  const showGoogleBlocks = async () => {
    console.log('HocrProofreaderWrapper: showGoogleBlocks:', selectedElement);
    // Fetch the SVG
    const response = await get(`/images/${selectedImage.id}.svg?bbox=${stringifySVGRectSet(hocrProofreaderInstance.current.selectedElements)}`)
    const svg = await response.text()
  
    // Create a container for the SVG
    let container = document.createElement('div');
    // Set the innerHTML of the container to the SVG data
    container.innerHTML = svg;
    // Get the SVG element from the container
    let newSvg = container.querySelector('svg');
    // Get the existing SVG element
    // Add an id to the new SVG
    newSvg.id = 'newSvg';
    let existingSvg = document.getElementById('layout-container').querySelector('svg');
    // Append the new SVG to the existing SVG
    existingSvg.appendChild(newSvg);
  }

  function stringifySVGRectSet(rectSet) {
    const rectArray = Array.from(rectSet);
    const stringifiedRects = rectArray.map(rect => JSON.parse(rect))
    return JSON.stringify(stringifiedRects);
  }

  const removeGoogleBlocks = () => {
    // Get the SVG with the id 'newSvg'
    let newSvg = document.getElementById('newSvg');
  
    // Remove the SVG if it exists
    if (newSvg) {
      newSvg.parentNode.removeChild(newSvg);
      removeGoogleBlocks();
    }
  }

  const imageUpdater = async (image) => {
    await handleImageUpdate(image, 'text');
    await handleImageUpdate(image, 'translation');
  }

  const handleImageUpdate = async (image, process) => {
    let lastLine = '';

    try {
      const response = await post(`images/${image.id}/${process}?force=true&bbox=${stringifySVGRectSet(hocrProofreaderInstance.current.selectedElements)}`,{}, {
        // Add any additional headers as required by the API
        headers: {
          'Content-Type': 'text/event-stream',
        },
      });
      // if (!response.ok) {
      //   throw new Error(`HTTP error! status: ${response.status}`);
      // }
      setTranslation([]);
      const reader = response.body
        .pipeThrough(new TextDecoderStream())
        .getReader()

      while (true) {
        const { value, done } = await reader.read()
        if (done) break
        // eslint-disable-next-line no-loop-func
        value.split('\n\n').forEach((line) => {
          if (!line || !line.length) return;
          lastLine = line;
          const obj = JSON.parse(line);
          if (!obj.object) return;
          switch (obj.type) {
            case 'image':
              setImage(obj.object);
              break;

            case 'translation':
            case 'phrase':
              setTranslation((translation) => {
                const index = translation.findIndex((item) => item.source === obj.object.source);

                if (index !== -1) {
                  // Replace the existing object
                  return [
                    ...translation.slice(0, index),
                    obj.object,
                    ...translation.slice(index + 1),
                  ];
                } else {
                  // Append the new object
                  return [...translation, obj.object];
                }
              });
              break;

            default:
          }
        });
      }

    } catch (error) {
      console.error('There was a problem with the fetch operation:', lastLine);
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  const handleToggleLayoutImage = () => {
    setShowingImage((prev) => !prev);
    hocrProofreaderInstance.current.toggleLayoutImage();
  };

  const handleSetZoom = (zoomLevel) => {
    hocrProofreaderInstance.current.setZoom(zoomLevel);
  };
  // Use useImperativeHandle to expose methods to the parent component
  useImperativeHandle(ref, () => ({
    toggleLayoutImage: () => {
      // Assuming HocrProofreader has a method to toggle layout image
      hocrProofreaderInstance.current.toggleLayoutImage();
    },
    setZoom: (zoomLevel) => {
      // Assuming HocrProofreader has a method to set zoom level
      hocrProofreaderInstance.current.setZoom(zoomLevel);
    },
    getSelectedElements: () => {
      // Assuming HocrProofreader has a method to get the selected element
      return hocrProofreaderInstance.current.selectedElements;
    },
    getHocr: () => {
      // Assuming HocrProofreader has a method to get the hOCR data
      return hocrProofreaderInstance.current.getHocr();
    },
    setHocr: () => {
      // Assuming HocrProofreader has a method to set the hOCR data
      return hocrProofreaderInstance.current.setHocr();
    }

  }));

  const ProofreaderToolbar = () => {

    if (!selectedImage) return <div></div>;
    console.log(selectedImage);
    return (
      <ButtonToolbar aria-label="Proofreader Tools">
        <Stack bsPrefix="doc-toolbar-stack" gap={3}>
          <p className="text-startqq" style={{ width: "-webkit-fill-available" }}>Image: #{selectedImage.id}</p>
          <ButtonGroup className="me-2" aria-label="Layout" size="sm">
            <ToggleButton variant="secondary" 
            type="checkbox"
            style={{textWrap:'nowrap', background: 'rgb(154, 128, 115)', border:0}} onClick={handleToggleLayoutImage}>{showingImage ? (<FileText/>) : (<FileImage/>)}</ToggleButton>
          </ButtonGroup>
          <ButtonGroup className="me-2" aria-label="Layout" size="sm">
            <Button style={{textWrap:'nowrap', background: 'rgb(154, 128, 115)',border:0}} 
            variant="secondary" onClick={toggleSvg}><Layers/></Button>
          </ButtonGroup>

          <Form.Select aria-label="Select Zoom" size="sm" defaultValue={'page-width'} onChange={(e) => handleSetZoom(e.target.value)}>
            <option value="page-full">Zoom Page Full</option>
            <option value="page-width">Zoom Page Width</option>
            <option value="original">Zoom Original</option>
          </Form.Select>

          {/* <ButtonGroup lassName="me-2" aria-label="Translations" size="sm">
                <Button variant="secondary" onClick={handleGetText}>Get Text</Button>
                <Button variant="secondary" onClick={handleTranslate}>Translate</Button>
            </ButtonGroup> */}
          {selectedImage &&
            (<>
              <Form.Check inline// prettier-ignore
                checked={selectedImage.translated ? 'disabled checked' : ''}
                type="switch"
                id="is-translated-switch"
                label="Translated"
                style={{textWrap:'nowrap'}} 
                onChange={(event) => {
                  event.stopPropagation();
                  handleImageUpdate(selectedImage, 'translation')
                }} />
              <Form.Check inline// prettier-ignore
                checked={selectedImage.textExtracted ? 'disabled checked' : ''}
                type="switch"
                label="OCR"
                id="is-text-extracted-switch"
                style={{textWrap:'nowrap'}} 
                onChange={(event) => {
                  event.stopPropagation();
                  handleImageUpdate(selectedImage, 'text')
                }} />
              <Trash3 onClick={(event) => {
                event.stopPropagation();
                // setShowModal(true);
                // handleDelImage(image);
              }}></Trash3>
            </>
            )
          }
        </Stack>
      </ButtonToolbar>
    );
  }

  function generatePlaceholders(numPlaceholders) {
    const placeholders = [];

    for (let i = 0; i < numPlaceholders; i++) {
      const numWords = Math.floor(Math.random() * 50) + 1; // Random number of words between 1 and 10
      const words = [];

      for (let j = 0; j < numWords; j++) {
        const length = Math.floor(Math.random() * 15) * 5; // Random length between 25 and 100
        words.push(<Placeholder className={`w-${length}`} key={j} />);
      }

      placeholders.push(
        <Placeholder as={Card.Text} animation="glow" key={i}>
          {words}
        </Placeholder>
      );
    }

    return placeholders;
  }

  return (
    (selectedImage && selectedImage.id) ?
    <div className="viewport">
      <Container fluid="lg">
        <Row lg sm={1} md={2} >

          <Col ref={layoutContainerRef} id="layout-container" style={{"maxHeight": "calc(100vh - 60px)"}} >
            {generatePlaceholders(4)}
          </Col>

          <Col className="editor-col flex-container">
            {/* <Stack> */}
            <Col ref={editorContainerRef} id="editor-container" sm={true} >
              {generatePlaceholders(4)}
            </Col>
            {/* <Offcanvas show={true} placement="bottom" responsive="lg" scroll={true} backdrop={false}> */}
            {/* <Offcanvas.Body> */}
            <Col ref={translationContainerRef} sm={true} className="editor translation container">
              {translation && translation?.map((phrase, idx) =>
              (phrase &&
                <div key={`phrase${idx}`} style={{ marginBottom: '10px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', direction: 'rtl', 'textAlign': 'left' }}>
                    <span style={{ 'textAlign': 'right', width: '100%' }}><strong>{phrase.source?.split(' ').map(word => {
                      const acronym = phrase.acronyms?.find(a => word.includes(a.acronym));// === word);
                      return acronym ? (
                        <OverlayTrigger
                          key={word}
                          placement="top"
                          overlay={
                            <Tooltip id={`tooltip-${word}`}>
                              {acronym.expanded.join(', ')}
                            </Tooltip>
                          }
                        >
                          <span style={{ textDecoration: 'underline dotted' }}>{word}</span>
                        </OverlayTrigger>
                      ) : word;
                    }).reduce((prev, curr, i) => [prev, ' ', curr])}</strong></span>
                    <div style={{ direction: 'ltr', width: '100%' }}>{phrase.translation}</div>
                  </div>
                  <div style={{ 'textAlign': 'left' }}><i>{phrase.explanation}</i></div>
                </div>
              ))}
              {(!translation || translation.length === 0) &&
                <Placeholder as={Card.Text} animation="glow" key={`phrase_placeholder`} style={{ marginBottom: '10px' }}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', direction: 'rtl', 'textAlign': 'left' }}>
                    <span style={{ 'textAlign': 'right', width: '100%' }}>
                      {generatePlaceholders(3)}
                    </span>
                    <div style={{ direction: 'ltr', width: '100%' }}><>
                      {generatePlaceholders(4)}
                    </></div>
                  </div>
                  <div style={{ 'textAlign': 'left' }}><i><>
                    {generatePlaceholders(4)}
                  </></i></div>
                </Placeholder>
              }
            </Col>
            {/* </Offcanvas.Body>
            </Offcanvas> */}
          </Col>
        </Row>
      </Container>
    </div> : <div></div>
    // <div class="viewport">
    // <div class="toolbar">
    //     <div class="logo">hOCR-Proofreader</div>

    //     <Button onClick={handleToggleLayoutImage}>Image/Text</Button>
    //     <div class="separator"></div>

    //     <span>Zoom:</span>
    //         /* <Button onClick={() => setZoom('page-full')}>Zoom Page Full</Button>
    //   <Button onClick={() => setZoom('page-width')}>Zoom Page Width</Button>
    //   <Button onClick={() => setZoom('original')}>Zoom Original</Button> */}
    // <div className="separator"></div>

    // {/* <Button onClick={handleSave}>Save</Button> */}
    // </div>
    // {/* <div id="layoutContainer" style={{ overflow: 'scroll' }}> */}

    // <div ref={layoutContainerRef} id="layoutContainer" style={{ overflow: 'scroll' }}>
    //     {/* Layout container where the HocrProofreader will render the layout */}
    //   </div>
    //   <div ref={editorContainerRef} id="editorContainer">
    //     {/* Editor container where the HocrProofreader will render the editor */}
    //   </div>
    //   {/* <svg className="layout">
    //     {createSvgElem('rect', { className: 'background', x: 0, y: 0, width: '100%', height: '100%', style: { fill: 'none' } })}
    //     {createSvgElem('image', { x: 0, y: 0, width: '100%', height: '100%' })}
    //     {createSvgElem('g', { className: 'words' })}
    //     {createSvgElem('g', { className: 'rects' })}

    //   </svg> */}
    //   {/* {hocrContent && <HocrToSvgComponent svgContent={hocrContent} />} */}
    // {/* </div> */}
    //     {/* <div id={layoutContainerId} ref={layoutRef} style={layoutSvgStyle} > */}
    //         {/* Render SVG or other components based on HOCR content */}
    //         {/* {renderTextBlocks()} */}
    //     {/* </div> */}
    //     {/* <div id={editorContainerId} ref={editorRef}> */}
    //         {/* {renderTextBlocks()} */}
    //         {/* Render editor iframe or equivalent interactive component */}
    //         {/* iframe could be replaced with a more React-friendly component */}
    //     {/* </div> */}
    // </div>
  );
});

export default memo(HocrProofreaderWrapper);
