import React, { useState, useEffect, useContext, useRef } from 'react';

// import { ToastContainer, toast } from 'react-toastify';
import { toast, Toaster } from 'react-hot-toast';
// import 'react-toastify/dist/ReactToastify.css';

import AuthProvider, { useAuth } from './components/AuthProvider';
import ErrorBoundary from './components/ErrorBoundary';
import ApiProvider from './components/ApiProvider';

// import Toast from 'react-bootstrap/Toast';
import ThemeProvider from 'react-bootstrap/ThemeProvider'

import ChassidNavbar, { NavbarProvider, useNavbar } from './components/ChassidNavbar'; // Adjust the path as necessary

import ImageProvider from './components/ImageProvider'; // Adjust the path as necessary
import ImageUploader from './components/ImageUploader'; // Adjust the path as necessary
import ImageGallery from './components/ImageGallery'; // Adjust the path as necessary
import HocrProofreaderWrapper from './components/HocrProofreaderWrapper'; // Adjust the path as necessary


import { Fab, Action } from 'react-tiny-fab';
import { Plus, List, Sliders } from 'react-bootstrap-icons';

import 'react-tiny-fab/dist/styles.css';

import './App.css';
// require('dotenv').config();

// import { useImages } from './components/ImageProvider';

import GoogleAuth from './components/GoogleAuth';

const theme = {
  colors: {
    primary: 'tomato',
    accent: 'yellow',
  },
};


function App() {
  // let image = {id: 1, url: "https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png"}

  const [signedIn, setSignedIn] = useState(false);


  // const [showGallery, setShowGallery] = useState(false);



  // const toggleShowGallery = () => setShowGallery((s) => !s);

  // const [selectedImageId, setSelectedImageId] = useState(null);
  // const [selectedImage, setSelectedImage] = useState(null);
  // const [openFileDialog, setOpenFileDialog] = useState(false);

  // const [updateImage, setUpdateImage] = useState(false);

  // const { selectedImage} = useImages();

  // const [images, setImages] = useState([]);

  const [toast, setToast] = useState({ show: false, message: '', variant: 'info' });

  const [currentTools, setCurrentTools] = useState(null);

  const [imageToUpload, setImageToUpload] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  // const showFileDialog = () => setOpenFileDialog((o) => !o);

  // const showErrorToast = (message) => {
  //   setToast({ show: true, message: message, variant: 'danger' });
  // };

  // const showInfoToast = (message) => {
  //   setToast({ show: true, message: message, variant: 'info' });
  // };

  // const hideToast = () => {
  //   setToast(prevToast => ({ ...prevToast, show: false }));
  // };



  // useEffect(() => {
  //   // if (signedIn)
  //   //   fetchImages();
  // }, [signedIn]);

  // useEffect(() => {
  //   if (selectedImageId) {
  //     const selected = images.find(image => image.id === selectedImageId);
  //     handleClose();
  //     setSelectedImage(selected);
  //     setSelectedImageId(null);
  //   }
  // }, [selectedImageId, images]);

  // useEffect(() => {
  //   if (openFileDialog)
  //     setTimeout(setOpenFileDialog(false), 250);
  // }, [openFileDialog]);

  
  const handleAddPageClick = async () => {
    try {
      const file = await window.handleGetFileLegacy();
      setImageToUpload(file);
    } catch (error) {
      console.error('Error getting file:', error);
    }
  };


  // const updateImage = (updatedImage) => {
  //   setImages(prevImages => prevImages.map(image =>
  //     image.id === updatedImage.id ? updatedImage : image
  //   ));
  // };

  const FAButton = () => {

    const { authenticated } = useAuth();
    const {showNav, toggleShowDocs} = useNavbar();

    // console.log(authenticated);
    return (
      <>
        {authenticated &&
          <Fab icon={<Plus size={'36'} />} mainButtonStyles={{ backgroundColor: '#9a8073' }}>
            <Action text="Add Page" style={{ backgroundColor: '#9a8073' }} onClick={handleAddPageClick}>
              <Plus size={'36'} />
            </Action>
            <Action text="Show Pages" style={{ backgroundColor: '#9a8073' }} onClick={toggleShowDocs}>
              <List size={'36'} />
            </Action>
            <Action className="page-status-action" text="Page Status" style={{ backgroundColor: '#9a8073' }} onClick={showNav}>
              <Sliders size={'28'} />
            </Action>
          </Fab>
        }

        {!authenticated &&
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <GoogleAuth  setSignedIn={setSignedIn} />
            
          </div>}
      </>
    );
  }



  return (
    <ErrorBoundary>
      <AuthProvider>
        <ApiProvider>
        <div className="App">
          <ThemeProvider dir="rtl" theme={theme} breakpoints={['xxl','xl','lg', 'md', 'sm']} minBreakpoint="sm" >
            <NavbarProvider>
            <Toaster stacked />
            <ImageProvider>

              <ChassidNavbar currentTools={currentTools} />
             
              <ImageGallery />
              {isUploading && <div>Processing... </div>}
              {/* {uploadProgress}% */}
              {/* <ImageWithOverlay tools={setCurrentTools} updateImage={updateImage} /> */}
              <HocrProofreaderWrapper toolbar={setCurrentTools} />
              <ImageUploader 
                image={imageToUpload}
                onImageChange={setImageToUpload} 
                onUploadChange={setIsUploading} 
                onUploadProgressChange={setUploadProgress}  />
              {/* openFileDialog={openFileDialog} */}
              <FAButton />
            </ImageProvider>
            </NavbarProvider>
          </ThemeProvider>
        </div>
        </ApiProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
}

export default App;
