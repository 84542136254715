/**
 * Copyright 2019 Google LLC
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// 'use strict';

/**
 * Google Analytics are disable for the demo.
 * To enable, uncomment the two sections marked "Enable Google Analytics Here.",
 * and update the Google Analytics site ID (UA-XXXXXXX-XX).
 */

import { gaEvent, gaTiming } from './rum.js';

const addAnalyticsScript = () => {
    /* eslint-disable */
    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
    /* esline-enable */

    /* Enable Google Analytics Here. */
    // ga('create', 'UA-XXXXXXX-XX', 'auto');
    // ga('set', 'transport', 'beacon');



    /**
     * Add Analytics script to page
     */


    window.addEventListener('DOMContentLoaded', () => {
  if (location.hostname === 'localhost') {
    // eslint-disable-next-line no-console
    console.log('🔕', 'Running on localhost, analytics not loaded.');
    return;
  }

  /* Enable Google Analytics Here. */
  // const gaScript = document.createElement('script');
  // gaScript.src = 'https://www.google-analytics.com/analytics.js';
  // document.head.appendChild(gaScript);

  });


/**
 * Analytics for window type: browser, standalone, standalone-ios
 */
window.addEventListener('load', () => {
    setTimeout(() => {
      let windowStyle = 'browser';
      if (window.navigator.standalone === true) {
        windowStyle = 'standalone-ios';
      } else if (matchMedia('(display-mode: standalone)').matches === true) {
        windowStyle = 'standalone';
      }
      gaEvent('Window Style', windowStyle, null, null, true);
    }, 3100);
  });
  
  /**
   * Performance analytics: load & paint
   */
  window.addEventListener('load', () => {
    if ('performance' in window) {
      const pNow = Math.round(performance.now());
      gaTiming('Start', 'window-load', pNow);
      setTimeout(() => {
        const paintMetrics = performance.getEntriesByType('paint');
        if (paintMetrics && paintMetrics.length > 0) {
          paintMetrics.forEach((entry) => {
            const name = entry.name;
            const time = Math.round(entry.startTime + entry.duration);
            gaTiming('Start', name, time);
          });
        }
      }, 3000);
    }
  });
  
  /**
   * Performance analytics: GA PageView, DOMContentLoaded
   */
  window.addEventListener('DOMContentLoaded', () => {
    if ('performance' in window) {
      const pNow = Math.round(performance.now());
      gaTiming('Start', 'dom-content-loaded', pNow);
    }
    if (window.ga) {
      window.ga('send', 'pageview', '/');
      // eslint-disable-next-line no-console
      console.log('👀', 'pageview', '/');
    }
  });
  
  /**
   * Log the app version.
   */
  window.addEventListener('load', () => {
    gaEvent('App Version', '[[VERSION]]', null, null, true);
  });
  
  /**
   * Log page visibility.
   */
  document.addEventListener('visibilitychange', (e) => {
    const state = document.hidden === true ? 'hidden' : 'visible';
    gaEvent('Page Visibility', state, null, null, true);
  });
  
};

export default addAnalyticsScript;