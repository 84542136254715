import React, { useContext, createContext } from "react";
import { useAuth } from "./AuthProvider";
import Api from "../utils/Api";
import Cookies from 'js-cookie';

export const ApiContext = createContext();
export const useAuthenticatedApi = () => useContext(ApiContext);   

const ApiProvider = ({ children }) => {
    // console.log('ApiProvider');

  
      const { logout } = useAuth();

      const authenticatedFetch = async (url, options = {}) => {
        const token = Cookies.get('jwt');

        if (token) {
            options.headers = {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            };
        }

        try {
            const response = await Api.fetch(url, options);

            if (!response.ok) {
                switch (response.status){
                case 401: case 403:
                    console.log('logout');
                    logout();  
                default:
                    // throw new HttpError(response, `${response.statusText}`);
                }
                // console.log('throw', response);
                throw new HttpError(response, `${response.statusText}`);

            }
            return response;

        } catch (error) {
            if (error.response.status === 401) {
                logout();
            }
            throw error;
        }
    };

    const get = async (url, options = {}) => {   
        return await authenticatedFetch(url, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            ...options,
        });
    }


      const post = async (url, body, options = {}) => {
        return await authenticatedFetch(url, {
            method: 'POST',
            body: body,
            headers: {
                'Content-Type': 'application/json',
            },
            ...options,
        });
    }   

    const put = async (url, body, options = {}) => {
        return await authenticatedFetch(url, {
            method: 'PUT',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            ...options,
        });
    }

      const postData = async (url, body, options = {}) => {
        return await authenticatedFetch(url, {
            method: 'POST',
            body: body,
            headers: { },
            ...options,
        });
    } 

    const del = async (url, options = {}) => {   
        return await authenticatedFetch(url, {
            method: 'DELETE',
            ...options,
        });
    }

    class HttpError extends Error {
        constructor(response, ...params) {
            super(...params);

            if (Error.captureStackTrace) {
                Error.captureStackTrace(this, HttpError);
            }

            this.response = response;
        }
    }

      return (
        <ApiContext.Provider value={{ get, post, put, del, postData  }}>
          {children}
        </ApiContext.Provider>
      );
}
export default ApiProvider;