import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';

import addFallback from './utils/fallback';

import addAnalyticsScript from './utils/GoogleAnalytics'
// import add from 'add';
// import { ConeStriped } from 'react-bootstrap-icons';



// Check if Service Workers are supported
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {

    navigator.serviceWorker.register('/service-worker.js')
          .then(registration => {
            // registration.unregister().then((boolean) => {
            //   // if boolean = true, unregister is successful
            //   console.log('SW unregistered: ', boolean);

            // });
              console.log('SW registered: ', registration);
          })
          .catch(registrationError => {
              console.log('SW registration failed: ', registrationError);
          });
  });
}

addAnalyticsScript();

const root = ReactDOM.createRoot(document.getElementById('root'));

addFallback(root);

root.render(
  <React.StrictMode>
    
    <App />
    
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
