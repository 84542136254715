import React, {useState} from 'react';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

import {useAuth} from './AuthProvider';


import Api from '../utils/Api';


// const CLIENT_ID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
const CLIENT_ID = '215134757050-sar2kt3vjrhs9cu4t4q76970o15lf0vh.apps.googleusercontent.com'


const GoogleAuth = ({ setSignedIn }) => {

    const { login } = useAuth();
    const [loading, setLoading] = useState(false);

    const onSuccess = async (credentialResponse) => {
        setLoading(true);
        const response = await Api.post('auth/google',credentialResponse);
        const data = await response.json();
        console.log(data);
        if (response.ok) {
            setSignedIn(true);
            login(data);
        } else {
            console.error('Error authenticating with Google:', data);
            setSignedIn(false);
            login(null);
        }
        setLoading(false);

    }

    return (
        <GoogleOAuthProvider clientId={CLIENT_ID}>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <GoogleLogin
                    onSuccess={onSuccess}
                    onError={() => {
                        console.log('Login Failed');
                        setSignedIn(false);
                        setLoading(false);
                    }}
                    auto_select
                />
            )}
        </GoogleOAuthProvider>
    );
}

export default GoogleAuth;