import React, { createContext, useState, useContext, useEffect, useRef } from 'react';

import { useAuth } from './AuthProvider';
import { useError } from './ErrorBoundary';


import { useAuthenticatedApi } from './ApiProvider';

import dayjs from 'dayjs';

// Create the context
export const ImageContext = createContext();

// Create the provider component
const ImageProvider = ({ children }) => {
    // console.log('ImageProvider');
    const fetchImagesCalled = useRef(false);

    const [images, setImages] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);

    const { authenticated } = useAuth();
    const { get, del } = useAuthenticatedApi();

    const { handleError } = useError();

    useEffect(() => {
        console.log('ImageProvider useEffect [authenticated]', authenticated);
        if(authenticated && !fetchImagesCalled.current){
            fetchImages();
            fetchImagesCalled.current = true;
        }else if(!authenticated){
            setSelectedImage(null);
            setImages([]);
            fetchImagesCalled.current = false;
        }
    }, [authenticated]);    

    useEffect(() => {
        if (images.length > 0 && !selectedImage) {
            selectImage(images[0]?.id)
        }
    }, [images, selectedImage]);

    // Utility methods

    const addImage = (newImage) => {
        setImages(prevImages => {
            const imageIndex = prevImages.findIndex(image => image.id === newImage.id || image.id === null);
            if (imageIndex !== -1) {
                // Replace the existing image
                return [...prevImages.slice(0, imageIndex), newImage, ...prevImages.slice(imageIndex + 1)];
            } else {
                setSelectedImage(newImage);
                // Prepend the new image
                return [newImage, ...prevImages];
            }
        });
    };

    const addImages = (newImages) => {
        setImages(prevImages => [...prevImages, ...newImages]);
    };


    const removeImage = async (id) => {
        try {
            const response = await del('images/' + id);

            if (!response.ok) {
                // showErrorToast(`HTTP error! status: ${response.status}`);
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setImages(prevImages => prevImages.filter(image => image.id !== id));
            // showInfoToast('Image deleted');

        } catch (error) {            
            console.error('Error deleting image:', error);
            // showErrorToast('Error deleting image');
            handleError(error);
        }
    };

    const selectImage = (imageId) => {
        const selected = images.find(image => image.id === imageId);
        setSelectedImage(selected);
    };

    const resetImages = () => {
        setImages([]);
    };

    const fetchImages = async () => {
        if(!authenticated) return;
        try {
            const response = await get('images');
            const data = await response.json();
            await setImages(data);
        } catch (error) {
            console.error('Error fetching images:', error);
            handleError(error);
        }
    };

    const fetchMoreImages = async () => {
        if(!authenticated) return;
        try {
            // Get the createdAt timestamp of the last image in the list
            const lastImageCreatedAt = images[images.length - 1]?.createdAt;

            if (!lastImageCreatedAt || !dayjs(lastImageCreatedAt).isValid) {
                return;
            }
            // Get more images after the last image in the list, less than the last image's createdAt
            const response = await get(`images?more=${lastImageCreatedAt}`);
            const data = await response.json();

            // Append the new images to the list
            setImages(prevImages => [...prevImages, ...data]);
        } catch (error) {
            console.log('Error fetching images:', error);
            handleError(error);
        }
    };

    return (
        <ImageContext.Provider value={{ images, selectedImage, addImage, removeImage, selectImage, addImages, resetImages, fetchImages, fetchMoreImages }}>
            {children}
        </ImageContext.Provider>
    );
};

// Custom hook to use the ImageContext
export const useImages = () => useContext(ImageContext);

export default ImageProvider;