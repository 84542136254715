



const apiHost = process.env.REACT_APP_API_URL || '../api/';

export default class Api {

    
    static fetch = async (url, options) => {

        return await fetch(`${apiHost}${url}`, options);

    };

    static get = async (url, options = {}) => {
        return await Api.fetch(url, {
            method: 'GET',
            ...options,
        });
    };
 
    static post = async (url, body, options = {}) => {
        return await Api.fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-Type': 'application/json',
            },
            ...options,
        });
    };
}