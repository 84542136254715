// Desc: ErrorBoundary component to catch errors and display a toast message
import React, { useState, useContext, createContext, useEffect } from "react";
import { toast } from 'react-hot-toast';

export const ErrorContext = createContext();

export const useError = () => {
  return useContext (ErrorContext);
};

export const ErrorProvider = ({children}) => {
  const [hasError, setHasError] = useState (false);
  const [error, setError] = useState (null);

  const handleError = (error) => {
    setError (error);
    setHasError (true);
  };

  useEffect(() => {
    if (hasError && error.message !== 'Unauthorized') {
      console.log('handleError',error);
      toast.error(error.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: 'bounce',
        });
    }
  }, [hasError, error]);

  return (
    <ErrorContext.Provider value={{ hasError, handleError, setError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export default class ErrorBoundary extends React.Component {

  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error };
    // return { hasError: true };
    

  }

  componentDidCatch(error, errorInfo) {
    // Catch errors in any components below and re-render with error message

    this.setState({
      error: error,
      errorInfo: errorInfo
    })
    // You can also log error messages to an error reporting service here
  }

  render() {
    // Normally, just render children

    if (this.state?.hasError && this.state?.error.message !== 'Unauthorized') {
      console.log(this.state.error);
      toast.error(this.state.error.message, {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
        transition: 'bounce',
        });
    }

    return (
      <ErrorProvider>
        {this.props.children}
      </ErrorProvider>
    );
  }
};