import React, { useState, useRef, useEffect, useCallback } from 'react';
// import Modal from 'react-bootstrap/Modal';
import { useAuthenticatedApi } from './ApiProvider';
import { useImages } from './ImageProvider';
// import FilePickerComponent from './FilePickerComponent'; // Adjust the import path as needed

import { toast } from 'react-hot-toast';// import Api from './ApiProvider'; // Assuming Api is exported from './Api.js'
// import { on } from 'pg/lib/query';
// import { useImages } from './ImageProvider';
// import { makeStreamingJsonRequest } from "http-streaming-request";
const jsonAutocomplete = require("json-autocomplete");


// addImageHandler={(image) => { addImage(image); setUpdateImage(true); }}
const ImageUploader = function ({ image, onImageChange, openFileDialog, onUploadChange, onUploadProgressChange }) {

  // const filePickerRef = useRef();

  // const [inputKey, setInputKey] = useState("");

  const [fileUploading, setFileUploading] = useState(false);

  const toastId = React.useRef(null);

  const { addImage, selectImage } = useImages();

  const { postData } = useAuthenticatedApi();

  const fileInput = useRef(null);

  const [uploadProgress, setUploadProgress] = useState(0);

  useEffect(() => {
    onUploadChange(fileUploading);
    onUploadProgressChange(uploadProgress)
  }, [fileUploading, onUploadChange, onUploadProgressChange, uploadProgress]);

  // const [showModal, setShowModal] = useState(openFileDialog);

  // const handleCloseModal = () => {
  //   setShowModal(false);
  // };

  // const handleOpenFileDialog = () => {
  //     // Check if the user is on a mobile device
  //     const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);
  //     if (isMobile || !fileInput?.current) {
  //       // If mobile device, open modal
  //       setShowModal(true);
  //     } else {
  //       // If desktop or device that supports it, open file selection with click(), open file dialog
  //       if (fileInput.current)
  //         fileInput.current.click();
  //     }
  // };

  useEffect(() => {
    // debugger;
    console.log('openFileDialog', openFileDialog);
    if (openFileDialog) {
      // filePickerRef.current.open();
      let file = null;
      window.handleGetFileLegacy().then(f => {
        console.log('File selected:', f);
        file = f;
      }).catch(error => {
        console.error('Error:', error);
      });
      console.log('ImageUploader', file);
    }
  }, [fileInput, openFileDialog]);

  useEffect(() => {
    const uploadImage = async (img) => {
      setFileUploading(true);
      // if (event.target.files && event.target.files[0]) {
      //   let img = event.target.files[0];

      // Prepare the image file to be sent in a FormData object
      const formData = new FormData();

      const total = img.size;
      formData.append('imageFile', img);

      // const reader = img.stream().getReader();
      // const newStream = new ReadableStream({
      //   start(controller) {
      //     function push() {
      //       reader.read().then(({done, value}) => {
      //         if (done) {
      //           controller.close();
      //           return;
      //         }

      //         setUploadProgress(prevProgress => prevProgress + value.byteLength * 100 / total);
      //          // check if we already displayed a toast
      //         if (toastId.current === null) {
      //           toastId.current = toast('Upload in Progress', { uploadProgress });
      //         } else {
      //           toast.update(toastId.current, { uploadProgress });
      //         }
      //         controller.enqueue(value);
      //         push();
      //       })
      //     }
      //     push();
      //   }
      // });

      // formData.set('imageFile', new File([newStream], img.name, {type: img.type}));

      // handleCloseModal();

      try {

        // add placeholder image
        const placeholderImg = { name: img.name, path: URL.createObjectURL(img) }
        addImage(placeholderImg);
        selectImage(placeholderImg);

        const response = await postData('images/get_ocr', formData, {
          // Add any additional headers as required by the API
          headers: {
            'Accept': 'text/event-stream',
          }
        });

        // setInputKey(Date.now());

        // if (!response.ok) {
        //   throw new Error(`HTTP error! status: ${response.status}`);
        // }

        const reader = response.body
          .pipeThrough(new TextDecoderStream())
          .getReader();

        while (true) {

          const { value, done } = await reader.read()
          if (done) break

          const o = JSON.parse(jsonAutocomplete(value));

          addImage(o.object);
          selectImage(o.object);
        }

      } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
      } finally {
        setFileUploading(false);
      }

      // }
    }

    if (image && !fileUploading) {
      const img = image;
      onImageChange(null)
      uploadImage(img);
    }

  }, [image, fileUploading]);

  return (
    <>
      {/* <FilePickerComponent ref={filePickerRef} onFileSelect={onImageChange} /> */}

      {/* <input type="file" ref={fileInput} key={inputKey+'1'} name="imageFile"
        onChange={onImageChange}
        accept="image/*"
        style={{ display: 'none' }} aria-label="Attach files"
      /> */}
      {/* <input type="file" accept="image/*" capture onChange="getFile($event)" /> */}
      {/* <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add a Page to Translate</Modal.Title>
        </Modal.Header>
        <Modal.Body>Select an image from your device or use your camera to take a picture?
        <input type="file" key={inputKey+'2'} name="imageFile" aria-label="Attach files"
        onChange={onImageChange}
        accept="image/*"
      /> */}


      {/* </Modal.Body> */}
      {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleCancelDelete}>
            Cancel
          </Button>
          <Button variant="danger" onClick={() => {
            // Delete the image
            setShowModal(false);
            deleteImage();
          }}>
            Delete
          </Button>
        </Modal.Footer> */}
      {/* </Modal> */}


    </>
  );

};
export default ImageUploader;